import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//GET DATA ALL ITEM
export const getProducts = createAsyncThunk('products/getProducts', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    sort: parameter?.sort,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/item/?by=def.created_date`, config)
  const responseData = {
    data: response.data.data.list_item,
    meta: response.data.meta,
  }

  return responseData
})

//LIST SELECT ITEM
export const getSelectItem = createAsyncThunk('product/getProduct', async () => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(
    `${URL_API}/v1/api/apps/item/v2/select?by=def.created_date`,
    config,
  )

  return response.data.data.list_item
})

//DETAIL ITEM
// export const getDetail = createAsyncThunk('products/getDetail', async (param) => {
//   const params = {
//     by: 'def.created_date',
//     item_id: param.item_id,
//   }

//   const response = await axios.get(`${URL_API}/v1/api/web/item/select?`, {
//     params,
//   })

//   return response.data.data.list_item
// })

//DETAIL ITEM NEW
export const getDetailItem = createAsyncThunk('products/getDetail', async (params) => {
  //console.log('paramsdata: ', params)
  const response = await axios.get(`${URL_API}/v1/api/web/item/id/` + params.item_id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })

  return response.data.data
})

//SEARCH ITEM
export const getSearchItem = createAsyncThunk('products/searchProduct', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(
    `${URL_API}/v1/api/web/item/?by=def.created_date&search=` + parameter.search,
    config,
  )
  const responseData = {
    data: response.data.data.list_item == null ? [] : response.data.data.list_item,
    meta: response.data.meta,
  }

  return responseData
})

//UPDATE ITEM
export const updateItem = createAsyncThunk('item/update', async ({ formdata }) => {
  let formObject = Object.fromEntries(formdata.entries())
  let formdatavalue = formObject.form_data
  let formData = JSON.parse(formdatavalue)

  const response = await axios
    .put(`${URL_API}/v1/api/web/item/id/` + formData.item_id, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error add')
    })

  // get 2 data
  const responseData = {
    data: response.data.data.list_item,
    meta: response.data.meta,
  }
  return responseData
})

const productEntity = createEntityAdapter({
  selectId: (product) => product.item_id,
})

const productSlice = createSlice({
  name: 'product',
  initialState: productEntity.getInitialState(),
  extraReducers: {
    [getProducts.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      productEntity.setAll(state, action.payload)
    },

    [getSelectItem.fulfilled]: (state, action) => {
      productEntity.setAll(state, action.payload)
    },

    // [getDetail.fulfilled]: (state, action) => {
    //   productEntity.setAll(state, action.payload)
    // },
    [getDetailItem.fulfilled]: (state, action) => {
      productEntity.setOne(state, action.payload)
    },

    [getSearchItem.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      productEntity.setAll(state, action.payload)
    },

    [updateItem.fulfilled]: (state, action) => {
      productEntity.updateOne(state, {
        item_id: action.payload.item_id,
        updates: action.payload,
      })
    },
  },
})

export const productSelector = productEntity.getSelectors((state) => state.product)
export const selectProduct = (state) => state.product
export default productSlice.reducer
