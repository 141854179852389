import { configureStore } from '@reduxjs/toolkit'
import productReducer from './reduxtoolkit/features/productSlice'
import categoryReducer from './reduxtoolkit/features/categorySlice'
import customerReducer from './reduxtoolkit/features/customerSlice'
import authReducer from './reduxtoolkit/features/authSlice'
import orderReducer from './reduxtoolkit/features/orderSlice'
import promoReducer from './reduxtoolkit/features/promoSlice'
import invoiceReducer from './reduxtoolkit/features/invoiceSlice'
import orderlistReducer from './reduxtoolkit/features/orderlistSlice'
import infoReducer from './reduxtoolkit/features/infoSlice'
import branchReducer from './reduxtoolkit/features/branchSlice'
import areaReducer from './reduxtoolkit/features/areaSlice'
import promoLineReducer from './reduxtoolkit/features/promoLineSlice'
import promoItemLineReducer from './reduxtoolkit/features/promoItemLineSlice'
import videoReducer from './reduxtoolkit/features/videoSlice'
import customerTypeReducer from './reduxtoolkit/features/customerTypeSlice'
import dashboardReducer from './reduxtoolkit/features/dashboardSlice'
import productLineReducer from './reduxtoolkit/features/productLineSlice'
import promoBonusReducer from './reduxtoolkit/features/promoBonusSlice'
import promoCategoryReducer from './reduxtoolkit/features/promoCategorySlice'
import roleReducer from './reduxtoolkit/features/roleSlice'
import rolegroupReducer from './reduxtoolkit/features/rolegroupSlice'
import dashboardCustReducer from './reduxtoolkit/features/dashboardCustSlice'
import dashboardSpReducer from './reduxtoolkit/features/dashboardSpSlice'
import dashboardAreaReducer from './reduxtoolkit/features/dashboardAreaSlice'
import dashboardBranchReducer from './reduxtoolkit/features/dashboardBranchSlice'
import dashboardBranchDetailReducer from './reduxtoolkit/features/dashboardBranchDetailSlice'
import userReducer from './reduxtoolkit/features/userSlice'
import partnerReducer from './reduxtoolkit/features/partnerSlice'
import salesmanReducer from './reduxtoolkit/features/salesmanSlice'
import omzetReducer from './reduxtoolkit/features/omzetSlice'
import omzetAreaReducer from './reduxtoolkit/features/omzetAreaSlice'
import omzetRegionReducer from './reduxtoolkit/features/omzetRegionSlice'
import omzetBranchReducer from './reduxtoolkit/features/omzetBranchSlice'
import omzetCustomerReducer from './reduxtoolkit/features/omzetCustomerSlice'
import omzetPerMonthReducer from './reduxtoolkit/features/omzetPerMonthSlice'
import reportCustReducer from './reduxtoolkit/features/reportCustSlice'
import reportAktivasiReducer from './reduxtoolkit/features/reportAktivasiSlice'
import reportTrackingInvoiceReducer from './reduxtoolkit/features/reportTrackingInvoiceSlice'
import broadcastReducer from './reduxtoolkit/features/broadcastSlice'
import voucherReducer from './reduxtoolkit/features/voucherSlice'
import voucherRedeemReducer from './reduxtoolkit/features/voucherRedeemSlice'
import salesmanTypeReducer from './reduxtoolkit/features/salesmanTypeSlice'
import reportPaidByVAReducer from './reduxtoolkit/features/reportPaidByVASlice'
import reportLogReducer from './reduxtoolkit/features/reportLogSlice'
import oldPriceReducer from './reduxtoolkit/features/oldPriceSlice'
import poinTransaksiReducer from './reduxtoolkit/features/poinTransaksiSlice'
import poinTokoReducer from './reduxtoolkit/features/poinTokoSlice'
import poinTokoBalanceReducer from './reduxtoolkit/features/poinTokoBalanceSlice'
import poinMekarReducer from './reduxtoolkit/features/poinMekarSlice'
import reportPoinTrackReducer from './reduxtoolkit/features/reportPoinTrackSlice'
import poinRedeemReducer from './reduxtoolkit/features/poinRedeemSlice'
import poinCouponReducer from './reduxtoolkit/features/poinCouponSlice'
import customerMaxPoinReducer from './reduxtoolkit/features/customerMaxPoinSlice'
import poinPromoReducer from './reduxtoolkit/features/poinPromoSlice'
import poinMaxReducer from './reduxtoolkit/features/poinMaxSlice'
import couponReducer from './reduxtoolkit/features/couponSlice'
import invoiceDocReducer from './reduxtoolkit/features/invoiceDocSlice'
import targetReducer from './reduxtoolkit/features/targetSlice'
import targetMonthReducer from './reduxtoolkit/features/targetMonthSlice'
import activityUserReducer from './reduxtoolkit/features/activityUserSlice'
import targetCategoryReducer from './reduxtoolkit/features/targetCategorySlice'
import reportPoinTokoReducer from './reduxtoolkit/features/reportPoinTokoSlice'

const initialState = {
  sidebarShow: true,
}

const changeStateReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

export const store = configureStore({
  reducer: {
    product: productReducer,
    category: categoryReducer,
    customer: customerReducer,
    auth: authReducer,
    order: orderReducer,
    promo: promoReducer,
    invoice: invoiceReducer,
    orderlist: orderlistReducer,
    info: infoReducer,
    branch: branchReducer,
    area: areaReducer,
    promoLine: promoLineReducer,
    promoItemLine: promoItemLineReducer,
    video: videoReducer,
    dashboard: dashboardReducer,
    customerType: customerTypeReducer,
    productLine: productLineReducer,
    promoBonus: promoBonusReducer,
    promoCategory: promoCategoryReducer,
    role: roleReducer,
    rolegroup: rolegroupReducer,
    dashboardCust: dashboardCustReducer,
    user: userReducer,
    partner: partnerReducer,
    salesman: salesmanReducer,
    dashboardSp: dashboardSpReducer,
    dashboardArea: dashboardAreaReducer,
    dashboardBranch: dashboardBranchReducer,
    dashboardBranchDetail: dashboardBranchDetailReducer,
    omzet: omzetReducer,
    omzetArea: omzetAreaReducer,
    omzetRegion: omzetRegionReducer,
    omzetBranch: omzetBranchReducer,
    omzetCustomer: omzetCustomerReducer,
    omzetPerMonth: omzetPerMonthReducer,
    reportCust: reportCustReducer,
    reportAktivasi: reportAktivasiReducer,
    reportTrackingInvoice: reportTrackingInvoiceReducer,
    broadcast: broadcastReducer,
    voucher: voucherReducer,
    voucherRedeem: voucherRedeemReducer,
    salesmanType: salesmanTypeReducer,
    reportPaidByVA: reportPaidByVAReducer,
    reportLog: reportLogReducer,
    oldPrice: oldPriceReducer,
    poinTransaksi: poinTransaksiReducer,
    poinToko: poinTokoReducer,
    poinTokoBalance: poinTokoBalanceReducer,
    poinMekar: poinMekarReducer,
    reportPoinTrack: reportPoinTrackReducer,
    poinRedeem: poinRedeemReducer,
    poinCoupon: poinCouponReducer,
    customerMaxPoin: customerMaxPoinReducer,
    poinPromo: poinPromoReducer,
    poinMax: poinMaxReducer,
    coupon: couponReducer,
    invoiceDoc: invoiceDocReducer,
    target: targetReducer,
    targetMonth: targetMonthReducer,
    activityUser: activityUserReducer,
    targetCategory: targetCategoryReducer,
    reportPoinToko: reportPoinTokoReducer,
    changeState: changeStateReducer,
  },
})

export default store
