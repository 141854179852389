import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getReportTrackingPoin = createAsyncThunk(
  'report/report-tracking-poin',
  async (parameter) => {
    const params = {
      // user_id: localStorage.getItem('user_id'),
      start_date: parameter.start_date,
      end_date: parameter.end_date,
      region_group_id: parameter.region_group_id,
      region_id: parameter.region_id,
      branch_id: parameter.branch_id,
      point_type: parameter.point_type,
      by: 'def.id',
      sort: 'desc',
    }
    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
    const response = await axios
      .get(`${URL_API}/v1/api/point/report?`, config)

      .catch((err) => {
        // console.log(err.response)
      })

    // return response.data.data == null ? [] : response.data.data

    const data = response.data.data == null ? [] : response.data.data

    // Tambahkan id unik (seperti rowIndex) pada setiap item di dalam data
    return data.map((item, index) => ({
      ...item,
      id: `${item.invoice_document_no}-${index}`, // Membuat id unik
    }))
  },
)

const reportPoinTrackEntity = createEntityAdapter({
  selectId: (reportPoinTrack) => reportPoinTrack.id,
})

const reportPoinTrackSlice = createSlice({
  name: 'reportPoinTrack',
  initialState: reportPoinTrackEntity.getInitialState(),
  extraReducers: {
    [getReportTrackingPoin.fulfilled]: (state, action) => {
      reportPoinTrackEntity.setAll(state, action.payload)
    },
  },
})

export const reportPoinTrackSelector = reportPoinTrackEntity.getSelectors(
  (state) => state.reportPoinTrack,
)
export default reportPoinTrackSlice.reducer
