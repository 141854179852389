import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

export const getReportPoinToko = createAsyncThunk(
  'report-poin-toko/report-poin-toko',
  async (parameter) => {
    const params = {
      by: 'def.id',
      sort: 'asc',
      region_group_id: parameter.region_group_id,
      region_id: parameter.region_id,
      branch_id: parameter.branch_id,
      show_in_app: parameter.show_in_app,
      installed: parameter.installed,
    }

    const config = {
      params: params,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      mode: 'no-cors',
    }

    const response = await axios
      .get(`${URL_API}/v1/api/point/report/customer_balance?`, config)

      .catch((err) => {
        // console.log(err.response)
      })

    return response.data.data == null ? [] : response.data.data
  },
)

const reportPoinTokoEntity = createEntityAdapter({
  selectId: (reportPoinToko) => reportPoinToko.customer_id,
})

const reportPoinTokoSlice = createSlice({
  name: 'reportPoinToko',
  initialState: reportPoinTokoEntity.getInitialState(),
  extraReducers: {
    [getReportPoinToko.fulfilled]: (state, action) => {
      reportPoinTokoEntity.setAll(state, action.payload)
    },
  },
})

export const reportPoinTokoSelector = reportPoinTokoEntity.getSelectors(
  (state) => state.reportPoinToko,
)
export default reportPoinTokoSlice.reducer
